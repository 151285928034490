import type { FC } from 'react';

import { ExchangeIcon } from '@kuna-pay/ui/icons';
import type { ButtonProps } from '@kuna-pay/ui/ui/button';
import { Button } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';

import appErrorImg from '../../images/app-error.png';
import styles from './splash-screen.error.module.scss';

type AppErrorScreenProps = {
  onReload?: ButtonProps['onClick'];

  description?: string;
};

const AppErrorScreen: FC<AppErrorScreenProps> = ({ onReload, description }) => (
  <div className={styles.root}>
    <img
      className={styles.img}
      width={480}
      height={320}
      src={appErrorImg}
      alt=''
    />

    <Typography className={styles.title} variant='h5'>
      Oops, something went wrong
    </Typography>

    {!!description && (
      <Typography className={styles.description} variant='body2'>
        {description}
      </Typography>
    )}

    {!!onReload && (
      <Button
        variant='text'
        color='primary'
        size='lg'
        start={<ExchangeIcon />}
        onClick={onReload}
      >
        Reload page
      </Button>
    )}
  </div>
);

export { AppErrorScreen };
