import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { JWTCompanyRole } from '@kuna-pay/core/entities/session';

import { CanAccess } from '@kuna-pay/merchant/entities/session';

import { CreateInvoicePage } from './pages/create';
import { InvoiceDetailsPage } from './pages/details';
import { InvoicesListPage } from './pages/list';
import { DiscoveryNotificationPaymentSettingsBanner } from './ui';

const InvoicePages: RouteObject[] = [
  {
    path: '/invoices/*',
    Component: () => (
      <>
        <Outlet />

        <CanAccess.Company.Role
          role={[JWTCompanyRole.Admin, JWTCompanyRole.Owner]}
        >
          <DiscoveryNotificationPaymentSettingsBanner />
        </CanAccess.Company.Role>
      </>
    ),
    children: [
      {
        index: true,
        Component: InvoicesListPage.Component,
      },
      {
        path: 'create',
        Component: CreateInvoicePage.Component,
      },
      {
        path: ':id',
        Component: InvoiceDetailsPage.Component,
      },
    ],
  },
];

export { InvoicePages };
