import { attach } from 'effector';
import { useStoreMap } from 'effector-react';

import { atom } from '@kuna-pay/utils/misc';
import type { JWTCompanyRole } from '@kuna-pay/core/entities/session';

import { $$jwt } from '../jwt.model';
import { $$session } from '../session.model';

const $$roleGuard = atom(() => {
  const assertCanAccessFx = attach({
    source: $$jwt.$$company.$role,
    effect: (role, allowedRoles: JWTCompanyRole[]) => {
      if (!role) {
        throw new Error('No role found');
      }

      if (!Array.isArray(allowedRoles) || allowedRoles.length === 0) {
        throw new Error('No allowed roles found');
      }

      if (!allowedRoles.includes(role)) {
        throw new Error('No access');
      }
    },
  });

  return {
    assert: {
      canAccessFx: assertCanAccessFx,
      canAccess: assertCanAccess,
    },

    fn: {
      canAccess,
    },

    hooks: {
      useCanAccess: (allowedForTypes: JWTCompanyRole | JWTCompanyRole[]) =>
        useStoreMap({
          store: $$session.$$jwt.$$company.$role,

          keys: [allowedForTypes],

          fn: (role, [allowedForRoles]) => canAccess(role, allowedForRoles),
        }),
    },
  };
});

function canAccess(
  role: JWTCompanyRole | null,
  allowedRoles: JWTCompanyRole[] | JWTCompanyRole
) {
  try {
    assertCanAccess(role, allowedRoles);

    return true;
  } catch (e) {
    return false;
  }
}

function assertCanAccess(
  type: JWTCompanyRole | null,
  allowedRoles: JWTCompanyRole[] | JWTCompanyRole
) {
  if (!type) {
    throw new Error('NOT_AUTHENTICATED');
  }

  const allowedList = Array.isArray(allowedRoles)
    ? allowedRoles
    : [allowedRoles];

  if (allowedList.length === 0) {
    throw new Error('NOT_ALLOWED_ROLES_FOUND');
  }

  if (!allowedList.includes(type)) {
    throw new Error('NOT_ALLOWED');
  }
}

export { $$roleGuard };
