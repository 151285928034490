import jwtDecode from 'jwt-decode';

import { APIPersist } from '@kuna-pay/merchant/shared/api/persist';

function getLanguageFromToken(): string | undefined {
  const payload = APIPersist.accessToken.read();

  if (!payload) return undefined;

  const decoded = jwtDecode<{ language: string }>(payload);

  if (!decoded) return undefined;

  return decoded.language;
}

export { getLanguageFromToken };
