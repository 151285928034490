import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { ExchangeIcon } from '@kuna-pay/ui/icons';
import { Link } from '@kuna-pay/ui/router';
import { Tabs } from '@kuna-pay/ui/ui/tabs';
import { JWTCompanyRole } from '@kuna-pay/core/entities/session';

import { CanAccess } from '@kuna-pay/merchant/entities/session';
import { routes } from '@kuna-pay/merchant/shared/router';
import { NavButton } from '@kuna-pay/merchant/shared/ui/nav-button';

import { SettingsLayoutTabs } from '../page.config';

const PaymentsCustomizationSettingsNavItem = {
  useNavLink: () => {
    const isActive = useMatch(routes.settings.paymentsCustomization);

    const to = routes.settings.paymentsCustomization.clone();

    if (!isActive) {
      to.searchParams.set('from', 'Settings');
    }

    return to;
  },

  Tab: memo(() => {
    const { t } = useTranslation();

    const to = PaymentsCustomizationSettingsNavItem.useNavLink();

    return (
      <CanAccess.Company.Role
        role={[JWTCompanyRole.Owner, JWTCompanyRole.Admin]}
      >
        <Tabs.Trigger value={SettingsLayoutTabs.PaymentsCustomization} asChild>
          <Link.ForwardRef to={to} variant='text'>
            {t('pages.settings.host.tabs.payments-customization')}
          </Link.ForwardRef>
        </Tabs.Trigger>
      </CanAccess.Company.Role>
    );
  }),
  NavButton: memo(() => {
    const { t } = useTranslation();

    const to = PaymentsCustomizationSettingsNavItem.useNavLink();

    return (
      <CanAccess.Company.Role
        role={[JWTCompanyRole.Owner, JWTCompanyRole.Admin]}
      >
        <NavButton to={to} icon={<ExchangeIcon />}>
          {t('pages.settings.host.tabs.payments-customization')}
        </NavButton>
      </CanAccess.Company.Role>
    );
  }),
};

export { PaymentsCustomizationSettingsNavItem };
