/* eslint-disable @typescript-eslint/lines-between-class-members,effector/no-getState */
import { GTMAnalytics } from '@kuna-pay/core/shared/analytics/google-tag-manager';

class APIGAIntegration {
  private static readonly CLIENT_ID_HEADER = 'x-ga-client-id';
  private static readonly SESSION_ID_HEADER = 'x-ga-session-id';

  private sessionId: string | null = null;
  private clientId: string | null = null;

  public constructor(private readonly GA_MEASUREMENT_ID: string) {
    this.initSessionId();
    this.initClientId();
  }

  public addHeaders(headers: Record<string, unknown>) {
    if (this.clientId) {
      headers[APIGAIntegration.CLIENT_ID_HEADER] = `${this.clientId}`;
    }

    if (this.sessionId) {
      headers[APIGAIntegration.SESSION_ID_HEADER] = `${this.sessionId}`;
    }
  }

  private initSessionId() {
    GTMAnalytics.gtag(
      'get',
      this.GA_MEASUREMENT_ID,
      'session_id',
      /**
       * This method will set the session id
       * even if cookie consent is not given
       */
      (firstTimeVisit) => {
        if (typeof firstTimeVisit === 'string') {
          /**
           * It's not really a last session, but a first time visit
           * according to local testing*
           */
          this.sessionId = firstTimeVisit;
        }
      }
    );
  }

  private initClientId() {
    GTMAnalytics.gtag(
      'get',
      this.GA_MEASUREMENT_ID,
      'client_id',
      (clientId) => {
        if (typeof clientId === 'string') {
          this.clientId = clientId;
        }
      }
    );
  }
}

export { APIGAIntegration };
