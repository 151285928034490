import { createContext, useContext } from 'react';

type UserMenuDropdownContextValue = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const UserMenuDropdownContext = createContext<UserMenuDropdownContextValue>({
  isOpen: false,
  setIsOpen: () => {},
});

const useUserMenuDropdownContext = () => {
  const context = useContext(UserMenuDropdownContext);

  if (!context) {
    throw new Error(
      'useUserMenuDropdownContext must be used within UserMenuDropdownContextProvider'
    );
  }

  return context;
};

const UserMenuDropdownContextProvider = UserMenuDropdownContext.Provider;

export { UserMenuDropdownContextProvider, useUserMenuDropdownContext };
