import { useUnit } from 'effector-react';
import QRCode from 'qrcode.react';
import type { FC } from 'react';
import { isAndroid } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '@kuna-pay/ui/ui/button';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { SetupMFAModel } from '../setup-mfa.model';
import styles from './secret.module.scss';

const OTPAuthenticatorAppSecret: FC = () => {
  const $$model = SetupMFAModel.useModel();

  const { t } = useTranslation('core');

  const { uri, secret, loading } = useUnit({
    secret: $$model.$secret,
    uri: $$model.$uri,
    loading: $$model.$pending,
  });

  if (loading) {
    return <Skeleton height={206} fullWidth />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.qrContainer}>
        <a
          /**
           * On Android `otpauth://` suggests to open the link in GoogleAuthenticator or similar app(like Authy).
           *
           * On iOS it suggests to set up 2FA for password autofill (?)
           *
           * If we replace `otpauth://` with `googleauthenticator://`, it will suggest to open the link in GoogleAuthenticator only on iOS.
           *
           * We can't detect that GoogleAuthenticator is unavailable on iOS. So if it's not, we do nothing
           */
          href={isAndroid ? uri : uri.replace('otpauth', 'googleauthenticator')}
        >
          <QRCode value={uri} size={100} />
        </a>
      </div>

      <div className={styles.codeContainer}>
        <Typography
          as='h3'
          className={styles.codeTitle}
          variant='subtitle5'
          center
        >
          {t('features.auth.mfa.setup.ui.authenticator-app-secret.title')}
        </Typography>

        <CopyButton className={styles.codeCopy} value={secret}>
          <Typography variant='subtitle4'>{secret}</Typography>
        </CopyButton>
      </div>
    </div>
  );
};

OTPAuthenticatorAppSecret.displayName = 'OTPAuthenticatorAppSecret';

export { OTPAuthenticatorAppSecret };
