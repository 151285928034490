class PersistStorage {
  public static create() {
    try {
      localStorage.getItem('any');

      return localStorage;
    } catch (error) {
      return new HeadlessStorage();
    }
  }
}

class HeadlessStorage implements Storage {
  #kv: Record<string, string> = {};

  public get length(): number {
    return Object.keys(this.#kv).length;
  }

  public getItem(key: string): string | null {
    return this.#kv[key] ?? null;
  }

  /**
   *
   * @note This method is not deterministic, as it depends on the order of keys in the object.
   */
  public key(index: number): string | null {
    return Object.keys(this.#kv)[index] ?? null;
  }

  public removeItem(key: string): void {
    if (key in this.#kv) {
      delete this.#kv[key];
    }
  }

  public setItem(key: string, value: string): void {
    this.#kv[key] = value;
  }

  public clear(): void {
    this.#kv = {};
  }
}

export { PersistStorage };
