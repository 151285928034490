import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import { Navigate } from '@kuna-pay/ui/router';

import { routes } from '@kuna-pay/merchant/shared/router';

import { $$jwt } from '../../model';

type EmailConfirmedGuardProps = PropsWithChildren<{}> & {};

/**
 * We assume that NonExpiredTokensGuard is used before this guard.
 */
const EmailConfirmedGuard = memo(({ children }: EmailConfirmedGuardProps) => {
  const isEmailConfirmed = useUnit($$jwt.$$user.$isConfirmedStatus);

  if (!isEmailConfirmed) {
    return <Navigate to={routes.auth.email.confirm} />;
  }

  return <>{children}</>;
});

export { EmailConfirmedGuard };
