/* eslint-disable @typescript-eslint/lines-between-class-members,effector/no-getState */
import type { BrowserClient as AmplitudeBrowserClient } from '@amplitude/analytics-types';

import { StoreProperty } from '@kuna-pay/utils/effector/property';
import type { IAmplitudeAnalyticsModel } from '@kuna-pay/core/shared/analytics';

class APIAmplitudeIntegration {
  private static readonly DEVICE_ID_HEADER = 'x-amplitude-user-id';
  private static readonly SESSION_ID_HEADER = 'x-amplitude-session-id';

  private readonly $instance: StoreProperty<AmplitudeBrowserClient>;

  public constructor(private readonly $$analytics: IAmplitudeAnalyticsModel) {
    this.$instance = StoreProperty.from(this.$$analytics.$instance);
  }

  public addHeaders(headers: Record<string, unknown>) {
    if (!this.$instance.value) {
      return;
    }

    const deviceId = this.$instance.value.getDeviceId();

    if (deviceId) {
      headers[APIAmplitudeIntegration.DEVICE_ID_HEADER] = deviceId;
    }

    const sessionId = this.$instance.value.getSessionId();

    if (sessionId) {
      headers[APIAmplitudeIntegration.SESSION_ID_HEADER] = `${sessionId}`;
    }
  }
}

export { APIAmplitudeIntegration };
