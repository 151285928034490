import { call } from '@kuna-pay/utils/effector';

import type { AvailableLanguages } from '@kuna-pay/merchant/generated/graphql';
import { $$api } from '@kuna-pay/merchant/shared/api';
import { updateProfile } from '@kuna-pay/merchant/shared/api/generated/Profile/request/updateProfile';

import { $$meQuery } from '../queries/me.query';

class UpdateProfileLanguageUseCase {
  public async execute(language: AvailableLanguages) {
    const result = await call(updateProfile({ language: true }), {
      data: {
        language,
      },
    });

    $$meQuery.updateCache({ language: result.language });
    await $$api.refreshTokens();
    await $$meQuery.revalidateInBackgroundFx();
  }
}

class UpdateProfileNamesUseCase {
  public async execute(params: { firstName: string; lastName: string }) {
    const { firstName, lastName } = await call(
      updateProfile({ firstName: true, lastName: true }),

      {
        data: {
          firstName: params.firstName,
          lastName: params.lastName,
        },
      }
    );

    $$meQuery.updateCache({ firstName, lastName });

    // Should await to get new tokens,
    // because next request will be faced with UNAUTHORIZED error otherwise
    await $$api.refreshTokens();

    await $$meQuery.revalidateInBackgroundFx();
  }
}

export { UpdateProfileLanguageUseCase, UpdateProfileNamesUseCase };
