enum JWTCompanyRole {
  Admin = 'CompanyAdmin',
  Owner = 'CompanyOwner',
  Agent = 'CompanyAgent',
  Accountant = 'CompanyAccountant',
}

enum JWTUserRole {
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
  User = 'User',
  Guest = 'Guest',
  OperatorL1 = 'OperatorL1',
  OperatorL2 = 'OperatorL2',
  AdvOperator = 'AdvOperator',
  ApiKey = 'ApiKey',
}

type AnyJWTRole = JWTCompanyRole | JWTUserRole;

export type { AnyJWTRole };
export { JWTCompanyRole, JWTUserRole };
