import { createDynamicFlag } from '../model/dynamic.factory';

const $$testDepositFeatureFlag = createDynamicFlag(
  'KUPAY_1738_MERCHANT_TEST_DEPOSIT'
);

const $$accountantRoleFeatureFlag = createDynamicFlag(
  'KUPAY_1498_FE_MERCHANT_ACCOUNTANT_ROLE'
);

const $$companyFeesFeatureFlag = createDynamicFlag(
  'KUPAY_2415_FE_MERCHANT_COMPANY_FEES'
);

const $$companyListVerificationStatusFeatureFlag = createDynamicFlag(
  'KUPAY_2615_FE_MERCHANT_COMPANY_LIST_VERIFICATION_STATUS'
);

const $$enableSettlementReportWorkerFlow = createDynamicFlag(
  'KUPAY_2586_IS_SETTLEMENT_REPORT_WORKER_FLOW_ENABLED'
);

const $$kybVerificationFlowFeatureFlag = createDynamicFlag(
  'KUPAY_2499_STORY_KYB_IDENTIFICATION_ENABLED'
);

export {
  $$accountantRoleFeatureFlag,
  $$companyFeesFeatureFlag,
  $$companyListVerificationStatusFeatureFlag,
  $$enableSettlementReportWorkerFlow,
  $$kybVerificationFlowFeatureFlag,
  $$testDepositFeatureFlag,
};
