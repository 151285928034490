/* eslint-disable @typescript-eslint/lines-between-class-members,effector/no-getState */

import type { ModelOf } from '@kuna-pay/utils/effector';
import { StoreProperty } from '@kuna-pay/utils/effector/property';

import type { RecaptchaModel } from './recaptcha.model';

class APIRecaptchaIntegration {
  private static readonly RECAPTCHA_HEADER = 'recaptcha';

  private readonly $token: StoreProperty<string | null>;

  public constructor($recaptcha: ModelOf<typeof RecaptchaModel>) {
    this.$token = StoreProperty.from($recaptcha.$token);
  }

  public addHeaders(headers: Record<string, unknown>) {
    const token = this.$token.value;

    if (token) {
      headers[APIRecaptchaIntegration.RECAPTCHA_HEADER] = token;
    }
  }
}

export { APIRecaptchaIntegration };
