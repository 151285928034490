import {
  isIOS,
  isIOS13,
  isIPad13,
  isIPhone13,
  isIPod13,
  isMacOs,
  isMobileSafari,
  isSafari,
} from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import { ExternalLink } from '@kuna-pay/ui/router';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import { SetupMFA } from '../../../../features/auth/mfa/setup';
import { urls } from '../../../../shared/config';
import { TurnOn2FARRPageModel } from './page.model';
import styles from './page.module.scss';

type TurnOn2FARRPageProps = {
  onWhatIsMfaClick?: () => void;
};

const isApple = [
  isIOS,
  isIOS13,
  isMacOs,
  isSafari,
  isMobileSafari,
  isIPod13,
  isIPhone13,
  isIPad13,
].some(Boolean);

const TurnOn2FARRPage = modelView(
  TurnOn2FARRPageModel,
  ({ onWhatIsMfaClick }: TurnOn2FARRPageProps) => {
    const $$model = TurnOn2FARRPageModel.useModel();
    const { t } = useTranslation('core', {
      keyPrefix: 'pages.auth.mfa.turn-on',
    });

    return (
      <AdaptivePaper className={styles.root}>
        <Typography as='h1' className={styles.title} variant='h5' center>
          {t('title')}
        </Typography>

        <Typography
          as='h2'
          className={styles.subtitle}
          variant='subtitle5'
          center
        >
          {t('description')}
          <br />
          <a
            href={
              isApple
                ? urls.authenticatorApp.appStore
                : urls.authenticatorApp.googlePlay
            }
            target='_blank'
            rel='noreferrer'
          >
            <Typography className={styles.subtitleLink} variant='subtitle5'>
              {t('suggested-authenticator')}
            </Typography>
          </a>
        </Typography>

        <div className={styles.formContainer}>
          <SetupMFA.Form $$model={$$model.$$confirm} />
        </div>

        <ExternalLink
          className={styles.whatIsMfa}
          href={urls.whatIs2FA}
          target='_blank'
          rel='noreferrer'
          onClick={onWhatIsMfaClick}
        >
          <Typography variant='subtitle5' center>
            {t('help')}
          </Typography>
        </ExternalLink>
      </AdaptivePaper>
    );
  }
);

export { TurnOn2FARRPage };
