import { forwardRef } from 'react';

import type { TextFieldProps } from '@kuna-pay/ui/ui/text-field';
import { TextField } from '@kuna-pay/ui/ui/text-field';

import type { FieldModel } from '../lib';
import { useField } from '../lib';

type FormTextFieldProps = Omit<
  TextFieldProps,
  'onChange' | 'onBlur' | 'isError' | 'value'
> & {
  field:
    | FieldModel<string | undefined>
    | FieldModel<string>
    | FieldModel<string | undefined | null>;

  hideValidation?: boolean;
  outlineFieldOnError?: boolean;

  mapValue?: (value: string) => string;
  mapOnChange?: (value: string) => string;
};

const useFormTextField = ({
  hideValidation,

  field,
  helperText,
  outlineFieldOnError,

  disabled,
  mapValue,
  mapOnChange,
}: Pick<
  FormTextFieldProps,
  | 'hideValidation'
  | 'field'
  | 'helperText'
  | 'outlineFieldOnError'
  | 'disabled'
  | 'mapValue'
  | 'mapOnChange'
>): Pick<
  TextFieldProps,
  | 'name'
  | 'value'
  | 'isError'
  | 'helperText'
  | 'disabled'
  | 'onChange'
  | 'onFocus'
  | 'onBlur'
> => {
  const {
    error: fieldError,
    shouldShowValidation,
    disabled: formDisabled,
    onBlur,
    onFocus,
    path,
    value: fieldValue,
    onChange: fieldOnChange,
  } = useField(field as FieldModel<string>);

  const error =
    !hideValidation && shouldShowValidation ? fieldError : undefined;

  /**
   *  For cases where u want to hide the helper text but still show the error outline
   */
  const isErrorOutline = !!(
    !!outlineFieldOnError && (shouldShowValidation ? fieldError : undefined)
  );

  return {
    name: path,
    value: mapValue ? mapValue(fieldValue) : fieldValue,
    onChange: (e) =>
      fieldOnChange(mapOnChange ? mapOnChange(e.target.value) : e.target.value),
    onFocus: onFocus,
    onBlur: onBlur,
    isError: !!error || isErrorOutline,
    /* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */
    helperText: error || helperText,
    disabled: !!disabled || !!formDisabled,
  };
};

const FormTextField = Object.assign(
  forwardRef<HTMLInputElement, FormTextFieldProps>(
    (
      {
        hideValidation,
        field,
        helperText,
        outlineFieldOnError,
        disabled,
        mapValue,
        mapOnChange,
        ...props
      },
      ref
    ) => {
      const textFieldProps = useFormTextField({
        hideValidation,
        field,
        helperText,
        outlineFieldOnError,
        disabled,
        mapValue,
        mapOnChange,
      });

      return <TextField ref={ref} {...textFieldProps} {...props} />;
    }
  ),
  {
    useFormTextField,
  }
);

export { FormTextField };
