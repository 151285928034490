import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';
import { Link } from '@kuna-pay/ui/router';
import { Button } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import { MFAActivatedRRPageModel } from './page.model';
import styles from './page.module.scss';

const MFAActivatedRRPage = modelView(MFAActivatedRRPageModel, () => {
  const $$model = MFAActivatedRRPageModel.useModel();
  const continueRoute = useUnit($$model.$continueRoute);
  const { t } = useTranslation('core', {
    keyPrefix: 'pages.auth.mfa.activated.ui',
  });
  const isMobile = useMediaQuery('(max-width: 575px)');

  return (
    <AdaptivePaper className={styles.root}>
      <div className={styles.imgContainer}>
        <img
          src='/images/illustration-success-large.png'
          alt=''
          width={326}
          height={160}
        />
      </div>

      <Typography
        className={styles.title}
        variant={!isMobile ? 'h5' : 'h7'}
        center
      >
        {t('title')}
      </Typography>

      <Typography
        as='h2'
        className={styles.subtitle}
        variant='subtitle5'
        center
      >
        {t('description')}
      </Typography>

      <Link to={continueRoute}>
        <Button variant='contained' color='primary' size='lg' fullWidth>
          {t('action')}
        </Button>
      </Link>
    </AdaptivePaper>
  );
});

export { MFAActivatedRRPage };
