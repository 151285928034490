import type { Effect, Event, Store } from 'effector';
import { combine } from 'effector';
import { createEffect, createEvent, createStore } from 'effector';

import { listen, setState } from '@kuna-pay/utils/effector';

type QueryStatus = 'initial' | 'pending' | 'done' | 'fail';

type CreateQueryConfig<TParams, TData, TInitialData = TData> = {
  query: (params: TParams) => Promise<TData>;
  initialData: TInitialData;
};

type QueryParams<TParams> = {
  params: TParams;
  options: { loading: boolean };
};

type EQuery<TParams, TData, TInitialData = TData> = {
  $data: Store<TData | TInitialData>;
  $error: Store<boolean>;
  $status: Store<QueryStatus>;
  $isLoading: Store<boolean>;
  $isFetching: Store<boolean>;
  $isError: Store<boolean>;

  startFx: Effect<TParams, TData>;
  refreshFx: Effect<TParams, TData>;

  finished: {
    done: Event<{ params: TParams; result: TData }>;
    doneData: Event<TData>;
    fail: Event<{ params: TParams; error: Error }>;
    failData: Event<Error>;
    finally: Event<
      | { status: 'done'; params: QueryParams<TParams>; result: TData }
      | { status: 'fail'; params: QueryParams<TParams>; error: Error }
    >;
  };

  reset: Event<void>;
};

function createEQuery<TParams, TData, TInitialData = TData>(
  config: CreateQueryConfig<
    TParams,
    TData,
    TInitialData extends never[] ? TData : TInitialData
  >
): EQuery<TParams, TData, TInitialData extends never[] ? TData : TInitialData> {
  const reset = createEvent();

  const $data = createStore<
    TData | (TInitialData extends never[] ? TData : TInitialData)
  >(config.initialData);
  const $error = createStore(false);
  const $isLoading = createStore(true);
  const $isFetching = createStore(false);
  const $status = createStore<QueryStatus>('initial');

  const getDataFx = createEffect({
    handler: async ({ params, options }: QueryParams<TParams>) => {
      try {
        if (options.loading) {
          setState($isLoading, true);
        }
        setState($isFetching, true);
        setState($status, 'pending');

        const data = await config.query(params);

        setState($error, false);
        setState($data, data);
        setState($isLoading, false);
        setState($isFetching, false);
        setState($status, 'done');

        return data;
      } catch (e) {
        setState($error, true);
        setState($data, config.initialData);
        setState($isLoading, false);
        setState($isFetching, false);
        setState($status, 'fail');

        throw e;
      }
    },
  });

  listen({
    clock: reset,
    handler: () => {
      setState($error, false);
      setState($data, config.initialData);
      setState($isLoading, true);
      setState($isFetching, false);
      setState($status, 'initial');
    },
  });

  return {
    $data,
    $error,
    $status,
    $isLoading,
    $isFetching,
    $isError: combine($error, (error) => !!error),

    startFx: createEffect({
      handler: (params: TParams) =>
        getDataFx({ params, options: { loading: true } }),
    }),

    refreshFx: createEffect({
      handler: (params: TParams) =>
        getDataFx({ params, options: { loading: false } }),
    }),

    finished: {
      done: getDataFx.done.map(({ params, result }) => ({
        params: params.params,
        result,
      })),
      doneData: getDataFx.doneData,
      fail: getDataFx.fail.map(({ params, error }) => ({
        params: params.params,
        error,
      })),
      failData: getDataFx.failData,
      finally: getDataFx.finally,
    },

    reset,
  };
}

export { createEQuery };
export type { EQuery };
