import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { useTypedGate } from '@kuna-pay/core/shared/router';

import { VerifyOneTimeCode } from '../@x';
import { SetupMFAModel } from '../setup-mfa.model';
import { OTPAuthenticatorAppSecret } from './secret';
import styles from './setup-form.module.scss';

const SetupMFAForm = modelView(SetupMFAModel, () => {
  const $$model = SetupMFAModel.useModel();
  const i18n = useTranslation('core');

  useTypedGate({ Gate: $$model.Gate, props: { i18n } });

  return (
    <>
      <OTPAuthenticatorAppSecret />

      <Typography as='h3' className={styles.title} variant='subtitle5_1' center>
        {i18n.t('features.auth.mfa.setup.ui.title')}
      </Typography>

      <VerifyOneTimeCode.Form
        name='setup-mfa-form'
        $$model={$$model.$$verifySecret}
      />
    </>
  );
});

SetupMFAForm.displayName = 'SetupMFAForm';

export { SetupMFAForm };
