import type { PropsWithChildren } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { VerificationGuard } from '@kuna-pay/merchant/entities/session';
import { MainLayout } from '@kuna-pay/merchant/widgets/layout';

import restrictedImg from '../../assets/restricred.asset.png';
import styles from './layout.module.scss';

const SettingsLayout = memo(({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <MainLayout.Merchant.Content className={styles.root}>
      <VerificationGuard
        from='Settings'
        unverified={
          <>
            <VerificationGuard.Img
              src={restrictedImg}
              width={190}
              height={190}
              alt=''
            />

            <VerificationGuard.Title>
              {t('pages.settings.host.unverified.title')}
            </VerificationGuard.Title>

            <VerificationGuard.Description>
              {t('pages.settings.host.unverified.description')}
            </VerificationGuard.Description>
          </>
        }
      >
        {children}
      </VerificationGuard>
    </MainLayout.Merchant.Content>
  );
});

export { SettingsLayout };
