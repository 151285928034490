import { createFeatureFlagFactory } from '@kuna-pay/core/shared/feature-flags';

import type { AvailableFeatureFlags } from '../feature-flags.types';
import { $$staticFeatures } from './static.model';

const createStaticFeatureFlag = createFeatureFlagFactory<AvailableFeatureFlags>(
  $$staticFeatures.$kv
);

type StaticFeatureFlag = ReturnType<typeof createStaticFeatureFlag>;

export { createStaticFeatureFlag };
export type { StaticFeatureFlag };
