import { createStaticFeatureFlag } from '../model/static.factory';

const $$isPLLangEnabledFeatureFlag = createStaticFeatureFlag(
  'KUPAY_1936_FE_MERCHANT_PL_LANGUAGE_ENABLED'
);

const $$isESLangEnabledFeatureFlag = createStaticFeatureFlag(
  'KUPAY_1936_FE_MERCHANT_ES_LANGUAGE_ENABLED'
);

const $$crowdinInContextFeatureFlag = createStaticFeatureFlag(
  'FE_MERCHANT_CROWDIN_IN_CONTEXT'
);

const $$hideIsAgreePrivacyPolicyCheckboxFeatureFlag = createStaticFeatureFlag(
  'KUPAY_1761_MERCHANT_HIDE_AGREE_PRIVACY_POLICY_CHECKBOX'
);

const $$kunaProAuthFeatureFlag = createStaticFeatureFlag(
  'KUPAY_1479_IS_KUNA_PRO_AUTH_ENABLED'
);

const $$recaptchaFeatureFlag = createStaticFeatureFlag('RECAPTCHA_ENABLED');

export {
  $$crowdinInContextFeatureFlag,
  $$hideIsAgreePrivacyPolicyCheckboxFeatureFlag,
  $$isESLangEnabledFeatureFlag,
  $$isPLLangEnabledFeatureFlag,
  $$kunaProAuthFeatureFlag,
  $$recaptchaFeatureFlag,
};
