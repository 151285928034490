import type { Store } from 'effector';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import type { IAtomicRRRoute } from '@kuna-pay/ui/router';
import { Link } from '@kuna-pay/ui/router';
import { Button } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import { EmailConfirmedPageModel } from './page.model';
import styles from './page.module.scss';

const EmailConfirmedPage = modelView(
  EmailConfirmedPageModel,
  ({ $continue }: { $continue: Store<IAtomicRRRoute> }) => {
    const to = useUnit($continue);
    const { t } = useTranslation('core', {
      keyPrefix: 'pages.auth.email.confirmed.ui',
    });

    return (
      <AdaptivePaper className={styles.root}>
        <div className={styles.imgContainer}>
          <img
            src='/images/confirm-email.png'
            alt=''
            width={244}
            height={244}
          />
        </div>

        <Typography as='h1' className={styles.title} variant='h7' center>
          {t('title')}
        </Typography>

        <Typography as='h2' className={styles.subtitle} variant='body3' center>
          {t('description')}
        </Typography>

        <Link to={to}>
          <Button variant='contained' color='primary' size='xl' fullWidth>
            {t('action')}
          </Button>
        </Link>
      </AdaptivePaper>
    );
  }
);

export { EmailConfirmedPage };
