/* eslint-disable @typescript-eslint/consistent-type-definitions */
interface IAtomicRRRoute<TParamsAndQuery extends Record<string, unknown> = {}> {
  path: string;

  searchParams: URLSearchParams;

  clone: (params?: TParamsAndQuery) => IAtomicRRRoute;

  toNavigate: () => string;

  withQuery: (queriesRecord: Record<string, string>) => IAtomicRRRoute;
}

class AtomicRRRoute<T extends Record<string, unknown> = {}>
  // eslint-disable-next-line prettier/prettier
  implements IAtomicRRRoute<T> {
  public searchParams: URLSearchParams;

  public constructor(public path: string) {
    this.searchParams = new URLSearchParams();
  }

  /**
   * Should be used to create a new instance of the route
   * so that the original route is not changed.
   * while the new instance can be changed (for example, add a query parameter).
   *
   * use case:
   * const routes = { foo: new AtomicRRRoute('/foo') };
   * ....
   * const route =  routes.foo.clone();
   * route.searchParams.set('bar', 'baz');
   * redirect(route);
   */
  public clone(params?: T, searchParams?: URLSearchParams): IAtomicRRRoute {
    let path = this.path;

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        path = path.replace(`:${key}`, value as string);
      });
    }

    const route = new AtomicRRRoute(path);
    route.searchParams = searchParams ?? new URLSearchParams(this.searchParams);

    return route;
  }

  public withQuery(queriesRecord: Record<string, string>): IAtomicRRRoute {
    return this.clone(undefined, new URLSearchParams(queriesRecord));
  }

  public toNavigate(): string {
    const search = this.searchParams.toString();

    if (search) {
      return `${this.path}?${search}`;
    }

    return this.path;
  }
}

export type { IAtomicRRRoute };
export { AtomicRRRoute };
