import type { Select } from '@kuna/graphql-util/dist/select';

import type { AuthTokensOutput } from '@kuna-pay/merchant/generated/graphql';
import { refreshToken } from '@kuna-pay/merchant/shared/api/generated/Auth/request/refreshToken';

class RefreshTokensUseCase {
  private static readonly SELECT: Select<AuthTokensOutput> = {
    accessToken: true,
    refreshToken: true,
  };

  private runningPromise: Promise<AuthTokensOutput> | null = null;

  public async execute(token: string): Promise<AuthTokensOutput> {
    return this.takeFirst(() =>
      refreshToken(RefreshTokensUseCase.SELECT)({
        refreshToken: token,

        //@ts-expect-error Untyped parameter required for the request to be sent skipping auth
        __noAuth: true,
      })
    );
  }

  private async takeFirst(query: () => Promise<AuthTokensOutput>) {
    if (this.runningPromise) {
      return this.runningPromise;
    }

    this.runningPromise = query().then(
      (result) => {
        this.runningPromise = null;

        return result;
      },
      (error) => {
        this.runningPromise = null;

        throw error;
      }
    );

    return this.runningPromise;
  }
}

export { RefreshTokensUseCase };
