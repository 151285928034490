import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch } from 'react-router-dom';

import { Link } from '@kuna-pay/ui/router';
import { Navigate } from '@kuna-pay/ui/router';
import { Tabs } from '@kuna-pay/ui/ui/tabs';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { $$companyFeesFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/dynamic';
import { routes } from '@kuna-pay/merchant/shared/router';
import { MainLayout } from '@kuna-pay/merchant/widgets/layout';

import { CompanyFeesLayoutTabs } from './page.config';
import styles from './page.module.scss';

const CompanyFeesRootPage = memo(() => {
  const { t } = useTranslation();

  const match = useMatch('/company-fees/:tab');
  const tab = match?.params?.tab;

  const companyFeesFeatureFlag = useUnit($$companyFeesFeatureFlag);

  if (companyFeesFeatureFlag.disabled) {
    return <Navigate to={routes.dashboard} />;
  }

  if (!tab) {
    return <Navigate to={routes.companyFees.invoiceDeposit} replace />;
  }

  return (
    <MainLayout.Merchant.Content className={styles.root}>
      <Typography className={styles.title} variant='h6'>
        {t('pages.company-fees.root.title')}
      </Typography>

      <Tabs value={tab} variant='round'>
        <Tabs.List className={styles.tabList}>
          <Tabs.Trigger value={CompanyFeesLayoutTabs.InvoiceDeposit} asChild>
            <Link.ForwardRef
              to={routes.companyFees.invoiceDeposit}
              variant='text'
            >
              {t('pages.company-fees.root.tabs.invoice-deposit')}
            </Link.ForwardRef>
          </Tabs.Trigger>

          <Tabs.Trigger value={CompanyFeesLayoutTabs.Withdrawal} asChild>
            <Link.ForwardRef to={routes.companyFees.withdrawal} variant='text'>
              {t('pages.company-fees.root.tabs.withdrawal')}
            </Link.ForwardRef>
          </Tabs.Trigger>

          <Tabs.Trigger value={CompanyFeesLayoutTabs.Payout} asChild>
            <Link.ForwardRef to={routes.companyFees.payout} variant='text'>
              {t('pages.company-fees.root.tabs.payout')}
            </Link.ForwardRef>
          </Tabs.Trigger>

          <Tabs.Trigger value={CompanyFeesLayoutTabs.Deposit} asChild>
            <Link.ForwardRef to={routes.companyFees.deposit} variant='text'>
              {t('pages.company-fees.root.tabs.deposit')}
            </Link.ForwardRef>
          </Tabs.Trigger>

          <Tabs.Trigger value={CompanyFeesLayoutTabs.Convert} asChild>
            <Link.ForwardRef to={routes.companyFees.convert} variant='text'>
              {t('pages.company-fees.root.tabs.convert')}
            </Link.ForwardRef>
          </Tabs.Trigger>
        </Tabs.List>

        <Outlet />
      </Tabs>
    </MainLayout.Merchant.Content>
  );
});

export { CompanyFeesRootPage };
export default CompanyFeesRootPage;
