import clsx from 'clsx';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import { memo } from 'react';
import { Fragment } from 'react';

import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';
import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { CookieConsent } from '@kuna-pay/core/shared/cookie-consent';

import { ContactSupport } from '@kuna-pay/merchant/features/contact-support';
import { Header } from '@kuna-pay/merchant/shared/ui/templates/header';

import { $$cookieConsent } from './model';
import {
  $$headerMenu,
  CompanyDropdown,
  ExceedLimitAlert,
  HeaderLogo,
  HeaderMenu,
  MerchantLayout,
  Navbar,
} from './ui';
import styles from './layout.module.scss';

const MainLayout = createCompoundComponent(
  (Components) =>
    ({ children }: PropsWithChildren) =>
      (
        <Components.Root>
          <Components.Header />

          <Components.Content>{children}</Components.Content>
        </Components.Root>
      ),
  {
    Root: Fragment,

    Header: memo(() => {
      const isTabletOrLess = useMediaQuery('(max-width: 1024px)');
      const isCompanyDropdownShown = CompanyDropdown.useIsCompanyAuthorized();

      return (
        <>
          <Header>
            {isCompanyDropdownShown ? (
              <>
                {!isTabletOrLess && <HeaderLogo />}

                <CompanyDropdown />
              </>
            ) : (
              <HeaderLogo />
            )}

            <HeaderMenu />
          </Header>

          <ExceedLimitAlert />

          <CookieConsent $$model={$$cookieConsent.$$ui.$$consent} />

          <ContactSupport $$model={$$headerMenu.$$contactSupport}>
            <div
              style={{
                // Modal need element to mount to,
                // but we don't want this element to influence layout (flex gap)
                position: 'fixed',
              }}
            />
          </ContactSupport>
        </>
      );
    }),

    Content: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.container, className)} {...props} />
    ),

    Merchant: MerchantLayout,

    Navbar,

    ExceedLimitAlert,

    HeaderMenu,
  }
);

export { MainLayout };
