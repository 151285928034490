import { useUnit } from 'effector-react';
import { Trans, useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import { Navigate } from '@kuna-pay/ui/router';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { AdaptivePaper } from '@kuna-pay/core/shared/ui/adaptive-paper';

import { TimeoutedResend } from '../../../../features/auth/abstract/timeouted-resend';
import { ConfirmEmail } from '../../../../features/auth/email/confirm';
import { ConfirmEmailRRPageModel } from './page.model';
import styles from './page.module.scss';

const ConfirmEmailRRPage = modelView(ConfirmEmailRRPageModel, () => {
  const $$model = ConfirmEmailRRPageModel.useModel();
  const { t } = useTranslation('core', {
    keyPrefix: 'pages.auth.email.confirm.ui',
  });

  const [email, isConfirmed] = useUnit([
    $$model.$email,
    $$model.$isEmailConfirmed,
  ]);

  if (isConfirmed) {
    return <Navigate to={$$model.redirectToOnConfirm} replace />;
  }

  return (
    <AdaptivePaper className={styles.root}>
      <div className={styles.imgContainer}>
        <img src='/images/confirm-email.png' alt='' width={244} height={244} />
      </div>

      <Typography as='h1' className={styles.title} variant='h7' center>
        {t('title')}
      </Typography>

      <Typography as='h2' className={styles.subtitle} variant='body3' center>
        <Trans
          t={t}
          i18nKey='description'
          values={{ email }}
          components={{
            b: <b />,
          }}
        />
      </Typography>

      <ConfirmEmail.Form $$model={$$model.$$confirm} />

      <Typography as='p' className={styles.resend} variant='body2' center>
        {t('resend.text')} <TimeoutedResend.Button $$model={$$model.$$resend} />
      </Typography>
    </AdaptivePaper>
  );
});

ConfirmEmailRRPage.displayName = 'ConfirmEmailPage';

export { ConfirmEmailRRPage };
