import clsx from 'clsx';
import { memo, useCallback, useState } from 'react';

import { useMediaQuery } from '@kuna-pay/utils/react/use-media-query';
import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { BaseButton } from '@kuna-pay/ui/ui/button';

import { ReactComponent as BurgerIcon } from '../../assets/burger.svg';
import { HeaderLogo } from '../logo.component';
import { Navbar } from '../navigation';
import { ChangeLanguage } from './change-language';
import { HeaderMenuContext } from './header-menu.context';
import { HelpMenu } from './help-menu';
import { Drawer } from './ui';
import { UserMenu } from './user-menu';
import styles from './header-menu.module.scss';

const HeaderMenu = createCompoundComponent(
  () => () => {
    const isTabletOrLess = useMediaQuery('(max-width: 1024px)');

    return <>{isTabletOrLess ? <MobileHeaderMenu /> : <DesktopHeaderMenu />}</>;
  },
  {
    UserMenu,
  }
);

const DesktopHeaderMenu = memo(() => (
  <div className={clsx(styles.root, styles.controls)}>
    <ChangeLanguage.Desktop />

    <HelpMenu.Desktop />

    <UserMenu.Desktop />
  </div>
));

const MobileHeaderMenu = memo(() => {
  const [open, setOpen] = useState(false);
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <Drawer.Root open={open} onOpenChange={setOpen} direction='right' modal>
      <Drawer.Trigger className={clsx(BaseButton.className(), styles.root)}>
        <BurgerIcon />
      </Drawer.Trigger>

      <Drawer.Content>
        <Drawer.Header>
          <HeaderLogo />

          <Drawer.Close />
        </Drawer.Header>

        <HeaderMenuContext.Provider value={onClose}>
          <div className={styles.navigation}>
            <UserMenu.MobileUserInfo />

            <Navbar.Mobile className={styles.navigationItem} />

            <UserMenu.Mobile.Profile />

            <HelpMenu.MobileNavigation />

            <ChangeLanguage.MobileNavigation />

            <UserMenu.Mobile.SignOut />
          </div>
        </HeaderMenuContext.Provider>
      </Drawer.Content>
    </Drawer.Root>
  );
});

export { HeaderMenu };
