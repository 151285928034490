import { useStoreMap, useUnit } from 'effector-react';
import type { PropsWithChildren, ReactNode } from 'react';
import { memo, useLayoutEffect } from 'react';

import { $$featureFlags } from '../model';

type SystemAuthorizedGuardProps = PropsWithChildren<{}> & {
  fallback?: ReactNode;
};

/**
 * We assume that NonExpiredTokensGuard is used before this guard.
 */
const AuthFeaturesGuard = memo(
  ({ children, fallback }: SystemAuthorizedGuardProps) => {
    const isListNonEmpty = useStoreMap(
      $$featureFlags.$$dynamicFeatures.$list,
      (list) => list.length > 0
    );
    const [isError, load] = useUnit([
      $$featureFlags.$$dynamicFeatures.$isError,
      $$featureFlags.loadDynamicFeaturesFx,
    ]);

    const isLoaded = isListNonEmpty || isError;

    useLayoutEffect(() => {
      load();
    }, []);

    if (!isLoaded) {
      return <>{fallback}</>;
    }

    return <>{children}</>;
  }
);

export { AuthFeaturesGuard };
