import { combine } from 'effector';
import jwtDecode from 'jwt-decode';
import { reshape } from 'patronum';

import { atom } from '@kuna-pay/utils/misc';
import { parseJwt } from '@kuna-pay/core/entities/session';

import {
  IdentificationStatus,
  StatusOfUser,
} from '@kuna-pay/merchant/generated/graphql';
import { $$api } from '@kuna-pay/merchant/shared/api';

const $$jwt = atom(() => {
  const $accessTokenJwt = combine($$api.$accessToken.$store, (token) => {
    try {
      if (!token) return null;

      return parseJwt(jwtDecode(token));
    } catch (e) {
      return null;
    }
  });

  return {
    $accessTokenJwt: $accessTokenJwt,

    $$user: reshape({
      source: $accessTokenJwt,

      shape: {
        $id: (state) => state?.id ?? null,

        $email: (state) => state?.email ?? null,

        $language: (state) => state?.language ?? null,

        $isOtpEnabled: (state) => !!state?.o,

        $isConfirmedStatus: (state) => state?.status === StatusOfUser.Confirmed,
      },
    }),

    $$company: reshape({
      source: $accessTokenJwt,

      shape: {
        $isCompanyLogged: (state) => !!state?.company,

        $id: (state) => state?.company?.id ?? null,

        $role: (state) => state?.company?.role ?? null,

        $identStatus: (state) => state?.company?.identStatus ?? null,

        $isVerified: (state) =>
          state?.company?.identStatus === IdentificationStatus.Accepted,

        $isVerifiedOrPending: (state) => {
          if (!state?.company) return false;

          return (
            state.company.identStatus === IdentificationStatus.Accepted ||
            state.company.identStatus === IdentificationStatus.Pending
          );
        },

        $isPendingVerification: (state) =>
          state?.company?.identStatus === IdentificationStatus.Pending,

        $type: (state) => state?.company?.type ?? null,

        $isLimitsExceeded: (state) => !!state?.company?.isLimitsExceeded,
      },
    }),
  };
});

export { $$jwt };
