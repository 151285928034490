import type { JwtPayload } from 'jwt-decode';

function isJWTExpired(jwt: JwtPayload) {
  try {
    //Fail fast
    if (!jwt?.exp) return true;

    const jwxExpiredInMS = jwt.exp * 1000;

    return Date.now() > jwxExpiredInMS;
  } catch (e) {
    return true;
  }
}

function isOTPEnabled(jwt: JwtPayload) {
  try {
    if (!jwt) return false;

    return 'o' in jwt && typeof jwt.o === 'boolean' && jwt.o;
  } catch (e) {
    return false;
  }
}

export { isJWTExpired, isOTPEnabled };
