import { combine } from 'effector';

import { atom } from '@kuna-pay/utils/misc';

import { $$session } from '@kuna-pay/merchant/entities/session';
import { $$logout } from '@kuna-pay/merchant/features/auth/logout';
import { ContactSupport } from '@kuna-pay/merchant/features/contact-support';

const $$headerMenu = atom(() => {
  const $$contactSupport = ContactSupport.factory.createModel();

  return {
    $$contactSupport,

    $$userMenu: {
      $isSystemLogged: combine($$session.$$jwt.$accessTokenJwt, Boolean),
      $isSystemLoggedPending: $$session.$$meQuery.$pending,

      $isCompanyLogged: combine($$session.$$merchantQuery.$data, Boolean),
      $isCompanyLoggedPending: $$session.$$merchantQuery.$pending,

      $$logout: $$logout,
    },
  };
});

export { $$headerMenu };
