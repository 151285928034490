/* eslint-disable @typescript-eslint/lines-between-class-members */
import type { Store } from 'effector';
import { persist } from 'effector-storage/local';

import { LocalStoragePersistKeys } from '@kuna-pay/merchant/shared/persist';

import { LocalStorageSerializer } from './serialize.persist';

class APIPersist {
  public static accessToken = new APIPersist(
    LocalStoragePersistKeys.AccessToken
  );
  public static refreshToken = new APIPersist(
    LocalStoragePersistKeys.RefreshToken
  );

  public constructor(public readonly key: LocalStoragePersistKeys) {}

  public read(): string | null {
    try {
      return LocalStorageSerializer.deserialize(localStorage.getItem(this.key));
    } catch (e) {
      return null;
    }
  }

  public write(value: string): void {
    try {
      localStorage.setItem(this.key, LocalStorageSerializer.serialize(value));
    } catch (e) {
      // pass
    }
  }

  public connect($store: Store<string | null>): void {
    persist({
      store: $store,
      key: this.key,
      deserialize: LocalStorageSerializer.deserialize,
      serialize: LocalStorageSerializer.serialize,
    });
  }
}

export { APIPersist };
