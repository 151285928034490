import clsx from 'clsx';
import { memo } from 'react';
import type { AuthCodeProps } from 'react-auth-code-input';
import ReactAuthCodeInput from 'react-auth-code-input';

import { BaseInput } from '../base-input';
import styles from '././code-input.module.scss';

type CodeInputProps = Omit<
  AuthCodeProps,
  | 'containerClassName'
  | 'allowedCharacters'
  | 'placeholder'
  | 'isPassword'
  | 'inputClassName'
> & {
  className?: string;
  classes?: {
    container?: string;
    input?: string;
  };
};

const CodeInput = memo(({ className, classes, ...props }: CodeInputProps) => (
  <ReactAuthCodeInput
    allowedCharacters='numeric'
    containerClassName={clsx(
      styles.container,
      className,
      classes?.container,
      classes
    )}
    inputClassName={clsx(styles.input, BaseInput.className, classes?.input)}
    autoFocus
    {...props}
  />
));

export { CodeInput };
export type { CodeInputProps };
