import clsx from 'clsx';
import type { PropsWithChildren, ReactNode } from 'react';
import { useState } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { DropdownProps } from '@kuna-pay/ui/ui/dropdown';
import { Dropdown } from '@kuna-pay/ui/ui/dropdown';

import { UserMenuDropdownContextProvider } from './dropdown.context';
import { HeaderMenuDropdownItem } from './dropdown-item.component';
import styles from './dropdown.module.scss';

type UserMenuDropdownProps = PropsWithChildren &
  Omit<
    DropdownProps,
    'rawTrigger' | 'open' | 'onOpenChange' | 'content' | 'asChild'
  > & {
    className?: string;
    content: ReactNode;
  };

const HeaderMenuDropdown = createCompoundComponent(
  () =>
    ({ content, className, children, ...props }: UserMenuDropdownProps) => {
      const [isOpen, setIsOpen] = useState(false);

      return (
        <UserMenuDropdownContextProvider value={{ isOpen, setIsOpen }}>
          <Dropdown
            align='end'
            maxDropdownHeight='unset'
            {...props}
            rawTrigger
            open={isOpen}
            onOpenChange={setIsOpen}
            content={
              <div className={clsx(styles.dropdown, className)}>{content}</div>
            }
            asChild
          >
            {children}
          </Dropdown>
        </UserMenuDropdownContextProvider>
      );
    },
  {
    Item: HeaderMenuDropdownItem,
  }
);

export { HeaderMenuDropdown };
