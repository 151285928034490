import { combine, createEffect, createStore } from 'effector';

import { setState } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import type { Feature } from '@kuna-pay/core/generated/public/graphql';

import { staticFeatures } from '../api';
import { listToKV } from '../lib';

const $$staticFeatures = atom(() => {
  const $list = createStore<Feature[]>([], {
    name: '$$feature-flags.$staticFeatures.$list',
  });

  const loadFx = createEffect({
    name: '$$feature-flags.$staticFeatures.loadFx',
    handler: async () => {
      const features = await staticFeatures({ name: true, value: true })();

      setState($list, features);

      return listToKV(features);
    },
  });

  const $kv = combine($list, listToKV);

  return {
    loadFx: loadFx,
    $list,
    $kv,
  };
});

export { $$staticFeatures };
