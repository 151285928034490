/* eslint-disable @typescript-eslint/lines-between-class-members */

import type { IAuthService } from '@kuna/graphql-client/dist/transport/AuthHttpTransport';
import type { Event, Store } from 'effector';
import { combine, createEvent } from 'effector';

import { listen, setState } from '@kuna-pay/utils/effector';
import { StoreProperty } from '@kuna-pay/utils/effector/property';
import {
  APIAmplitudeIntegration,
  APIGAIntegration,
} from '@kuna-pay/core/shared/api/integrations';
import { APIRecaptchaIntegration } from '@kuna-pay/core/shared/recaptcha';

import type { AuthTokensOutput } from '@kuna-pay/merchant/generated/graphql';
import { $$analytics } from '@kuna-pay/merchant/shared/analytics';
import { environment } from '@kuna-pay/merchant/shared/config';

import { $$recaptcha } from '../recaptcha';
import { APIResponseCode } from './api.config';
import { APIPersist } from './persist';
import { RefreshTokensUseCase } from './use-cases';

class ApiService {
  public events: {
    loggedOut: Event<void>;
    tokenUpdated: Event<void>;
    tokenCleared: Event<void>;
  } = {
    loggedOut: createEvent(),
    tokenUpdated: createEvent(),
    tokenCleared: createEvent(),
  };

  public $accessToken: StoreProperty<string | null>;
  public $refreshToken: StoreProperty<string | null>;
  public $otp: StoreProperty<string | null>;

  public $tokens: Store<{
    accessToken: string | null;
    refreshToken: string | null;
  }>;

  public constructor(
    private readonly refreshTokenUseCase: RefreshTokensUseCase
  ) {
    this.$accessToken = StoreProperty.create(APIPersist.accessToken.read());
    this.$refreshToken = StoreProperty.create(APIPersist.refreshToken.read());
    this.$otp = StoreProperty.create<string | null>(null);

    this.$tokens = combine({
      accessToken: this.$accessToken.$store,
      refreshToken: this.$refreshToken.$store,
    });

    this.setupOutsideEvents();
    this.setupPersist();
  }

  private setupOutsideEvents() {
    listen({
      /**
       * Listen for the token changes
       *
       * Note: this event is not triggered on the initial load with persist
       * because of sync nature of effector-persist which doesn't trigger updates
       * without explicit event
       */
      clock: this.$accessToken.$store.updates,
      handler: (token) => {
        if (!token) {
          this.events.tokenCleared();
        } else {
          this.events.tokenUpdated();
        }
      },
    });
  }

  private setupPersist() {
    APIPersist.accessToken.connect(this.$accessToken.$store);
    APIPersist.refreshToken.connect(this.$refreshToken.$store);
  }

  public refreshTokens = async (): Promise<AuthTokensOutput> => {
    if (!this.$refreshToken.value) return Promise.reject();

    const tokens = await this.refreshTokenUseCase.execute(
      this.$refreshToken.value
    );

    this.updateTokens(tokens);

    return tokens;
  };

  public updateTokens = (tokens: AuthTokensOutput) => {
    setState(this.$accessToken.$store, tokens.accessToken);
    setState(this.$refreshToken.$store, tokens.refreshToken);
  };

  public clearTokens() {
    setState(this.$accessToken.$store, null);
    setState(this.$refreshToken.$store, null);

    this.clearOtp();
  }

  public setOtp = (otp: string) => {
    setState(this.$otp.$store, otp);
  };

  public clearOtp = () => {
    setState(this.$otp.$store, null);
  };

  public createAuthServiceForGQLClient = (): IAuthService => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const amplitudeIntegration = new APIAmplitudeIntegration($$analytics);
    const gaIntegration = new APIGAIntegration(
      environment.google.analytics.GA_ID
    );
    const recaptchaIntegration = new APIRecaptchaIntegration($$recaptcha);

    return {
      refresh: async () => {
        await self.refreshTokens();
      },
      logout: async () => {
        this.clearTokens();

        this.events.loggedOut();
      },
      isUnauthorized: (request: unknown) => {
        if (!Array.isArray(request)) return false;

        return request.some((item) => {
          const statusCode =
            item?.errors?.[0]?.extensions?.exception?.response?.statusCode;

          if (statusCode === APIResponseCode.Unauthorized) {
            return true;
          }

          if (!item.errors) {
            return false;
          }

          return item.errors[0]?.code === 'UNAUTHORIZED';
        });
      },
      isAuthRequest: (request) => {
        if (!Array.isArray(request)) return false;

        if (request.length === 0) return false;

        return !request[0].variables?.__noAuth;
      },
      addHeaders: (headers) => {
        amplitudeIntegration.addHeaders(headers);
        gaIntegration.addHeaders(headers);
        recaptchaIntegration.addHeaders(headers);

        if (self.$accessToken.value) {
          headers['jwt-token'] = this.$accessToken.value;
        }

        if (self.$otp.value) {
          headers.otp = this.$otp.value;
        }
      },
    };
  };
}

const $$api = new ApiService(new RefreshTokensUseCase());

export { $$api, ApiService };
