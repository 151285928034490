enum LocalStoragePersistKeys {
  AccessToken = 'accessToken',
  RefreshToken = 'refreshToken',

  RefCurrency = 'ref-currency',

  PaymentPreferencesDiscoveryPopup = 'payment-preferences-discovery-popup',
}

export { LocalStoragePersistKeys };
