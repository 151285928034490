import { createQuery } from '@farfetched/core';
import type { Select } from '@kuna/graphql-util/dist/select';

import { ASSET_SELECT } from '@kuna-pay/core/entities/asset';

import { createQueryWithImperativeCacheAccess } from '@kuna-pay/merchant/entities/session/model/queries/lib.query';
import type { CompanyDetailsOutput } from '@kuna-pay/merchant/generated/graphql';
import { findCompanyDetails } from '@kuna-pay/merchant/shared/api/generated/Company/request/findCompanyDetails';

const SELECT: Select<CompanyDetailsOutput> = {
  id: true,
  title: true,
  avatar: true,
  createdAt: true,
  type: true,
  status: true,
  identificationStatus: true,

  isLimitsExceeded: true,
  // `$$referenceCurrency.$asset` implicitly depends on it
  ReferenceCurrencyAsset: ASSET_SELECT,

  AutoConvertAsset: ASSET_SELECT,

  isVolatilityCoverageEnabled: true,
};

const $$merchantQuery = Object.assign(
  createQueryWithImperativeCacheAccess(
    createQuery({
      name: 'session/queries/company',

      handler: async (_: void) => {
        const response = await findCompanyDetails(SELECT)();

        return response;
      },
    })
  ),
  {
    SELECT,
  }
);

export { $$merchantQuery };
