import { attach } from 'effector';
import { useStoreMap } from 'effector-react';

import { atom } from '@kuna-pay/utils/misc';

import type { CompanyType } from '@kuna-pay/merchant/generated/graphql';

import { $$jwt } from '../jwt.model';
import { $$session } from '../session.model';

const $$companyTypeGuard = atom(() => {
  const assertCanAccessFx = attach({
    source: $$jwt.$$company.$type,
    effect: (type, allowedTypes: CompanyType[]) => {
      assertCanAccess(type, allowedTypes);
    },
  });

  return {
    assert: {
      canAccessFx: assertCanAccessFx,
      canAccess: assertCanAccess,
    },

    fn: {
      canAccess,
    },

    hooks: {
      useCanAccess: (allowedForTypes: CompanyType | CompanyType[]) =>
        useStoreMap({
          store: $$session.$$jwt.$$company.$type,

          keys: [allowedForTypes],

          fn: (type, [allowedForTypes]) => canAccess(type, allowedForTypes),
        }),
    },
  };
});

function canAccess(
  type: CompanyType | null,
  allowedTypes: CompanyType[] | CompanyType
) {
  try {
    assertCanAccess(type, allowedTypes);

    return true;
  } catch (e) {
    return false;
  }
}

function assertCanAccess(
  type: CompanyType | null,
  allowedTypes: CompanyType[] | CompanyType
) {
  if (!type) {
    throw new Error('NOT_AUTHENTICATED');
  }

  const allowedList = Array.isArray(allowedTypes)
    ? allowedTypes
    : [allowedTypes];

  if (allowedList.length === 0) {
    throw new Error('NOT_ALLOWED_COMPANY_TYPES_FOUND');
  }

  if (!allowedList.includes(type)) {
    throw new Error('NOT_ALLOWED');
  }
}

export { $$companyTypeGuard };
