import { useUnit } from 'effector-react';
import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { $$redirectOnSignIn } from '../../model';

const RedirectOnSignInCatchall: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const [savePath, saveCompanyId] = useUnit([
    $$redirectOnSignIn.savePath,
    $$redirectOnSignIn.saveCompanyId,
  ]);

  useEffect(() => {
    savePath(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const companyId = searchParams.get(
      $$redirectOnSignIn.URL_QUERY_PARAMS_CONFIG.COMPANY_ID
    );

    if (companyId) {
      saveCompanyId(companyId);
    }
  }, [location]);

  return <>{children}</>;
};

export { RedirectOnSignInCatchall };
