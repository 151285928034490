import type { KunaPayCompanyJwtPayload } from '@kuna/kuna-pay-jwt';
import { z } from 'zod';

import {
  CompanyType,
  IdentificationStatus,
  StatusOfCompany,
  StatusOfUser,
} from '@kuna-pay/core/generated/public/graphql';

import { JWTCompanyRole } from './config';

// library dupe bc nest imports
enum KunaPayJwtTypes {
  USER = 'u',
  API_KEY = 'k',
}

const jwtSchema = z.object({
  id: z.string(),
  email: z.string(),
  language: z.string(),
  username: z.string(),
  fingerprint: z.string(),
  jwtId: z.string(),
  s: z.boolean(),
  o: z.boolean(), // isOtpEnabled
  merchantId: z.string(),
  t: z.enum([KunaPayJwtTypes.USER]),
  status: z.nativeEnum(StatusOfUser),
  identStatus: z.string(),
  role: z.string(),
  company: z
    .object({
      id: z.string(),
      role: z.nativeEnum(JWTCompanyRole),
      type: z.nativeEnum(CompanyType),

      groups: z.array(
        z.object({
          id: z.string(),
          type: z.string(),
        })
      ),
      status: z.nativeEnum(StatusOfCompany),
      identStatus: z.nativeEnum(IdentificationStatus),
      isLimitsExceeded: z.coerce.boolean().optional(),
    })

    .optional(),
});

const parseJwt = (payload: KunaPayCompanyJwtPayload) => {
  const result = jwtSchema.safeParse(payload);

  return result.success ? result.data : null;
};

export { jwtSchema, KunaPayJwtTypes, parseJwt };
