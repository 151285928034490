import { useUnit } from 'effector-react';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FAQIcon, GuidesIcon, SupportIcon } from '@kuna-pay/ui/icons';
import { Divider } from '@kuna-pay/ui/ui/divider';
import { urls } from '@kuna-pay/core/shared/config';

import { ContactSupport } from '@kuna-pay/merchant/features/contact-support';
import { $$companyFeesFeatureFlag } from '@kuna-pay/merchant/shared/feature-flags/features/dynamic';
import { routes } from '@kuna-pay/merchant/shared/router';
import { Nav } from '@kuna-pay/merchant/widgets/layout/ui/navigation/nav';

import { ReactComponent as CompanyFeesIcon } from '../../../assets/company-fees.svg';
import { HeaderMenuContext } from '../header-menu.context';
import { $$headerMenu } from '../header-menu.model';
import styles from './help-menu.module.scss';

const MobileHelpMenuNavigation = memo(() => {
  const { t, i18n } = useTranslation();

  const [companyFeesFeatureFlag, isLoggedIntoCompany] = useUnit([
    $$companyFeesFeatureFlag.$enabled,
    $$headerMenu.$$userMenu.$isCompanyLogged,
  ]);
  const closeMobileDrawer = useContext(HeaderMenuContext);

  return (
    <>
      {companyFeesFeatureFlag && (
        <>
          {isLoggedIntoCompany && (
            <Nav.Item.Root
              title={t('widgets.layout.header-menu.help-menu.company-fees')}
              className={styles.mobileNavigationItem}
              to={routes.companyFees.root}
              onClick={closeMobileDrawer}
            >
              <Nav.Item.Icon>
                <CompanyFeesIcon />
              </Nav.Item.Icon>

              <Nav.Item.Text>
                {t('widgets.layout.header-menu.help-menu.company-fees')}
              </Nav.Item.Text>
            </Nav.Item.Root>
          )}
        </>
      )}

      <ContactSupport.Trigger $$model={$$headerMenu.$$contactSupport}>
        <Nav.Item.Root
          title={t('widgets.layout.header-menu.help-menu.support')}
          className={styles.mobileNavigationItem}
          asChild
        >
          <div>
            <Nav.Item.Icon>
              <SupportIcon />
            </Nav.Item.Icon>

            <Nav.Item.Text>
              {t('widgets.layout.header-menu.help-menu.support')}
            </Nav.Item.Text>
          </div>
        </Nav.Item.Root>
      </ContactSupport.Trigger>

      <Nav.Item.Root
        title={t('widgets.layout.header-menu.help-menu.guides')}
        className={styles.mobileNavigationItem}
        asChild
      >
        <a
          href={urls.docs.root.withLocale(i18n.language)}
          target='_blank'
          rel='noreferrer'
          onClick={(e) => e.currentTarget.blur()}
        >
          <Nav.Item.Icon>
            <GuidesIcon />
          </Nav.Item.Icon>

          <Nav.Item.Text>
            {t('widgets.layout.header-menu.help-menu.guides')}
          </Nav.Item.Text>
        </a>
      </Nav.Item.Root>

      <Nav.Item.Root
        title={t('widgets.layout.header-menu.help-menu.faq')}
        className={styles.mobileNavigationItem}
        asChild
      >
        <a
          href={urls.docs.onboarding.withLocale(i18n.language)}
          target='_blank'
          rel='noreferrer'
          onClick={(e) => e.currentTarget.blur()}
        >
          <Nav.Item.Icon>
            <FAQIcon />
          </Nav.Item.Icon>

          <Nav.Item.Text>
            {t('widgets.layout.header-menu.help-menu.faq')}
          </Nav.Item.Text>
        </a>
      </Nav.Item.Root>

      <Divider />
    </>
  );
});

export { MobileHelpMenuNavigation };
