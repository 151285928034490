import { createEvent, createStore } from 'effector';

import { call, getState, listen, setState } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import { notify } from '@kuna-pay/ui/notification';

import { $$session } from '@kuna-pay/merchant/entities/session';
import type {
  AvailableLanguages,
  UserProfileInformationOutput,
} from '@kuna-pay/merchant/generated/graphql';
import { $$i18n } from '@kuna-pay/merchant/shared/i18n';

const $$changeLanguage = atom(() => {
  const changeLanguage = createEvent<AvailableLanguages>(
    '$$changeLanguage.changeLanguage'
  );

  const $changingLanguageTo = createStore<AvailableLanguages | null>(null, {
    name: '$$changeLanguage.$changingLanguageTo',
  });

  listen({
    clock: changeLanguage,
    source: $$i18n.$instance,
    handler: async (language, i18n) => {
      void call(i18n.changeLanguage, language);
      setState($changingLanguageTo, language);

      const me: UserProfileInformationOutput | null = await getState(
        $$session.$$me.$$query.$data
      );

      if (me && me.language !== language) {
        try {
          await $$session.$$me.updateProfileLanguageFx(language);
        } catch (e) {
          notify.warning(
            i18n.t(
              'features.change-language.model.sync-with-profile.failed.unknown',
              { lng: language }
            )
          );
        }
      }

      const current = await getState($changingLanguageTo);
      const isNewChangeNotStarted = current === language;

      if (isNewChangeNotStarted) {
        setState($changingLanguageTo, null);
      }
    },
  });

  return {
    $$ui: {
      changeLanguage,
      $changingLanguageTo,
    },
  };
});

export { $$changeLanguage };
