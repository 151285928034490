import { combine, createEffect, createStore, sample } from 'effector';

import { setState } from '@kuna-pay/utils/effector';
import { atom, bridge } from '@kuna-pay/utils/misc';
import type { Feature } from '@kuna-pay/core/generated/public/graphql';

import { $$api } from '@kuna-pay/merchant/shared/api';
import { publicFeatures } from '@kuna-pay/merchant/shared/api/generated/Feature/request/publicFeatures';

import { listToKV } from '../lib';
import { $$staticFeatures } from './static.model';

const $$featureFlags = atom(() => {
  const $$dynamicFeatures = atom(() => {
    const $list = createStore<Feature[]>([], {
      name: '$$feature-flags.$$dynamicFeatures.$list',
    });

    const $isError = createStore(false, {
      name: '$$feature-flags.$$dynamicFeatures.$isError',
    });

    const loadFx = createEffect({
      name: '$$feature-flags.$$dynamicFeatures.loadFx',
      handler: async () => {
        try {
          const features = await publicFeatures({ name: true, value: true })();

          setState($isError, false);

          setState($list, features);

          return listToKV(features);
        } catch (e) {
          setState($isError, true);
          throw e;
        }
      },
    });

    const $kv = combine($list, listToKV);

    bridge(() => {
      sample({
        // Should update feature flags when token is updated
        // because info in token may be changed
        // thus **auth** feature-flags could become stale
        clock: $$api.events.tokenUpdated,
        target: loadFx,
      });

      sample({
        clock: $$api.events.tokenCleared,
        target: $list.reinit!,
      });
    });

    return {
      loadFx: loadFx,
      $list,
      $kv,
      $isError,
    };
  });

  const $features = combine(
    $$staticFeatures.$kv,
    $$dynamicFeatures.$kv,
    (nonAuth, auth) => ({ ...nonAuth, ...auth })
  );

  return {
    loadStaticFeaturesFx: $$staticFeatures.loadFx,
    loadDynamicFeaturesFx: $$dynamicFeatures.loadFx,

    $features,
    $staticFeatures: $$staticFeatures.$kv,
    $dynamicFeatures: $$dynamicFeatures.$kv,

    $$staticFeatures,
    $$dynamicFeatures,
  };
});

export { $$featureFlags };
