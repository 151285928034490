import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { MFAActivatedPage } from './activated';
import { TurnOn2FAPage } from './turn-on';

const MFAPages: RouteObject[] = [
  {
    path: '/2fa/*',
    children: [
      {
        path: 'turn-on',
        Component: TurnOn2FAPage.Component,
      },
      {
        path: 'activated',
        Component: MFAActivatedPage.Component,
      },
      {
        path: '*',
        element: <Navigate to='turn-on' replace />,
      },
    ],
  },
];

export { MFAPages };
