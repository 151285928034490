import { useUnit } from 'effector-react';
import jwtDecode from 'jwt-decode';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import { Navigate } from '@kuna-pay/ui/router';
import { isJWTExpired } from '@kuna-pay/core/entities/session';

import { $$api } from '@kuna-pay/merchant/shared/api';
import { routes } from '@kuna-pay/merchant/shared/router';

type NonExpiredTokensGuardProps = PropsWithChildren<{}> & {};

const NonExpiredTokensGuard = memo(
  ({ children }: NonExpiredTokensGuardProps) => {
    const { accessToken, refreshToken } = useUnit($$api.$tokens);

    if (!accessToken || !refreshToken) {
      return <Navigate to={routes.auth.signIn} />;
    }

    if (!canDecodeJWT(accessToken)) {
      return <Navigate to={routes.auth.signIn} />;
    }

    const isAccessTokenExpired = isJWTExpired(jwtDecode(accessToken));

    if (isAccessTokenExpired) {
      if (!canDecodeJWT(refreshToken)) {
        return <Navigate to={routes.auth.signIn} />;
      }

      const isRefreshTokenExpired = isJWTExpired(jwtDecode(refreshToken));

      if (isRefreshTokenExpired) {
        return <Navigate to={routes.auth.signIn} />;
      }
    }

    return <>{children}</>;
  }
);

function canDecodeJWT(token: string) {
  try {
    jwtDecode(token);

    return true;
  } catch (error) {
    return false;
  }
}

export { NonExpiredTokensGuard };
