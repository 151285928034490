import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { ProfilePage } from './page.component';
import { ProfileMainInfoPage } from './pages/main-info';
import { ProfileNotificationsPage } from './pages/notifications';

const ProfilePages: RouteObject[] = [
  {
    path: '/profile/*',
    Component: ProfilePage,
    children: [
      {
        path: 'main',
        Component: ProfileMainInfoPage.Component,
      },
      {
        path: 'notifications',
        Component: ProfileNotificationsPage.Component,
      },
      {
        path: '*',
        element: <Navigate to='main' replace />,
      },
    ],
  },
];

export { ProfilePages };
