/**
 * Build environment
 */

if (import.meta.env.PROD) {
  try {
    console.log(
      'Bundled at:',
      new Date(parseInt(import.meta.env.CREATED_AT)).toLocaleString('UK')
    );
  } catch (e) {
    console.error('Failed to parse bundle date. \n', e);
  }
}

enum AppStage {
  DEV = 'DEV',
  PROD = 'PROD',
}

const environment = {
  stage: import.meta.env.STAGE,
  development: import.meta.env.DEV,
  production: import.meta.env.PROD,

  KunaPay: {
    checkout: {
      url: import.meta.env.ACCEPT_PAYMENT_FRONT_PUBLIC_URL,
    },
  },

  backend: {
    gw: {
      url: import.meta.env.API_URL,
    },
    ws: {
      url: import.meta.env.WS_URL,
    },
  },

  KunaPro: {
    url: import.meta.env.KUNA_URL,
    clientId: import.meta.env.KUNA_CLIENT_ID,
  },

  google: {
    clientId: import.meta.env.GOOGLE_CLIENT_ID,

    analytics: {
      GTM_ID: import.meta.env.GTM_ID,
      GA_ID: import.meta.env.GA_ID,
    },
  },

  reCaptcha: {
    siteKey: import.meta.env.RE_CAPTCHA_SITE_KEY,
  },

  liveChat: {
    licence: import.meta.env.LIVE_CHAT_LICENSE,
  },

  sentry: {
    dsn: import.meta.env.SENTRY_DSN,
    environment: import.meta.env.SENTRY_ENVIRONMENT,
    release: import.meta.env.SENTRY_RELEASE_COMMIT_SHA,
  },

  amplitude: {
    apiKey: import.meta.env.AMPLITUDE_API_KEY,
    release: import.meta.env.SENTRY_RELEASE_COMMIT_SHA,
  },

  clarity: {
    id: import.meta.env.MICROSOFT_CLARITY_ID,
  },
};

if (import.meta.env.STAGE !== 'PROD') {
  console.log('Environment', { environment });
}

export { AppStage, environment };
